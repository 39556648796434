.react-select.react-select {
  font-size: get-font-size(s);
  font-weight: get-font-weight(normal);
  z-index: 10;

  &__control {
    border: 2px solid get-color(basic);
    border-radius: 0;
    cursor: pointer;
    flex-wrap: unset;

    &:hover {
      border-color: get-color(basic, semidarker);
    }
  }

  &__single-value {
    margin: 0;
    max-width: none;
    position: relative;
    transform: none;
    text-overflow: unset;
    top: initial;
  }

  &__indicator-separator {
    display: none;
  }

  &__value-container {
    flex-wrap: unset;
    flex: none;
  }

  svg path {
    fill: get-color(basic, semidarker);
  }

  &__menu {
    border-radius: 0;
  }

  &__option {
    $color: rgba(get-color(secondary), 0.2);
    $active-color: rgba(get-color(secondary), 0.4);

    background-color: transparent;

    &:hover {
      background-color: $color;
    }

    &:active {
      background-color: $active-color;
    }

    &--is-focused {
      background-color: $color;
    }

    &--is-selected {
      color: get-color(basic, darker);
      background-color: $active-color;

      &:hover {
        background-color: $active-color;
      }
    }
  }
}
