//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.slider {
  outline: none;

  &-video_wrapper {
    margin-bottom: 0.5rem;
    position: relative;
    height: 12rem;

    @media (min-width: get-media(xs)) {
      height: 14rem;
    }

    @media (min-width: get-media(s)) {
      height: 16rem;
    }

    @media (min-width: get-media(m)) {
      height: 18rem;
    }

    @media (min-width: get-media(l)) {
      height: 20rem;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border: 2px solid get-color(basic, darker);
    background-color: get-color(basic, brightest);
    padding: 0;

    &:hover {
      border-color: get-color(primary, normal);
      color: get-color(basic, darker);
    }

    &:active,
    &:focus {
      background-color: rgba(get-color(basic, darker), 0.05);
      border-color: get-color(basic, darker);
      color: get-color(basic, darker);
    }

    &.is-disabled {
      background-color: get-color(basic, brightest);
      border: 2px solid get-color(basic, normal);

      path {
        fill: get-color(basic, normal);
      }
    }
  }

  &-img_wrapper {
    position: relative;
    height: 0;
    display: flex;
    padding-bottom: 12rem;

    @media (min-width: get-media(xs)) {
      padding-bottom: 14rem;
    }

    @media (min-width: get-media(s)) {
      padding-bottom: 16rem;
    }

    @media (min-width: get-media(m)) {
      padding-bottom: 18rem;
    }

    @media (min-width: get-media(l)) {
      padding-bottom: 20rem;
    }
  }

  &-img {
    background-color: get-color(basic, bright);
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  &-controls {
    justify-content: space-between;
  }

  &-captions {
    font-weight: 700;
    z-index: 1;
  }
}
