//
// Basic variables for the application,
// to import variables you have to import
// the file `variables.scss`.
//
// Consider to use 'shirt' sizes for naming if you can,
// typical shirt sizes are xs, s, m, l ,xl
// but you can add more xxxs, xxs, s, sm, m, ml, xxxxxl.
//

// Colors
$color-primary: (
  brightest: #e5f4fa,
  brighter: null,
  bright: #e5e1ff,
  normal: #6959e4,
  dark: #005b85,
  darker: #4837ca,
  darkest: null
);

$color-secondary: (
  normal: #90e2c9,
  dark: #57bb9d,
  darker: #06bc85
);

$color-basic: (
  brightest: #fff,
  brighter: #edeef1,
  bright: #f5f5f7,
  normal: #e1e2e6,
  dark: #bdbec6,
  semidarker: #696c73,
  darker: #141318,
  semidarkest: #131313,
  darkest: #000,
  success: #60aa11,
  error: #f25b62
);

// Add all colors to this map
$color: (
  primary: $color-primary,
  secondary: $color-secondary,
  basic: $color-basic
);

// Special colors
$twitter-color: #1da1f2;
$facebook-color: #3b5998;
$linkedin-color: #0077b5;
$footer-color: #f8f8f8;

// Special sizes
$select-height: 2.5rem;
$header-space: 1rem;
$header-height: $select-height + $header-space * 2;
$header-height-s: $select-height + $header-space * 2;

$footer-space: 1.5rem;
$footer-i-size: 2rem;
$footer-height: $footer-i-size + $footer-space * 2;
$footer-height-s: ($footer-i-size * 3) + ($footer-space * 3.5);
$footer-height-m: ($footer-i-size * 2) + ($footer-space * 3);

// Body height
$main-height: calc(100vh - #{$header-height} - #{$footer-height});
$main-height-s: calc(100vh - #{$header-height-s} - #{$footer-height-s});
$main-height-m: calc(100vh - #{$header-height-s} - #{$footer-height-m});

// Times
$bootstrap-carousel-time: 0.6s;
$time: (
  slow: 0.5s,
  normal: 0.35s,
  fast: 0.15s
);

// Fonts
$font-family-montserrat: 'Montserrat';

$font-weight: (
  light: 200,
  semilight: 300,
  normal: 500,
  medium: 600,
  bold: 700,
  black: 800
);

// s: 0.875rem  14px
// m: 1rem      [16px]
// ml: 1.125rem 18px
// l: 1.3125rem 21px
// xl: 1.5rem   24px
// xxl: 2.25rem 38px
$font-size: (
  s: 0.875rem,
  m: 1rem,
  ml: 1.125rem,
  l: 1.3125rem,
  xl: 1.5rem,
  xxl: 1.75rem
);

// Medias (Only as example, use the values you really need)
$media: (
  xxs: 375px,
  xs: 640px,
  s: 768px,
  m: 1024px,
  l: 1280px,
  xl: 1440px,
  xxl: 1660px
);

// Z-index
$z: (
  xs: 1,
  s: 10,
  m: 20,
  l: 30,
  xl: 50,
  xxl: 100
);

//shadow

$main-box-shadow: 0 20px 56px 0 rgba(map-get($color-basic, semidarkest), 0.15);
$collapsible-shadow: 0 8px 20px 0 rgba(map-get($color-basic, semidarkest), 0.15);
$img-link-shadow: 0 12px 20px 0 rgba(map-get($color-basic, darker), 0.3);
