//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.footer {
  align-items: center;
  background-color: $footer-color;
  border-top: 1px solid get-color(basic, brighter);
  bottom: 0;
  height: $footer-height;
  padding: $footer-space 0;
  width: 100%;

  @media screen and (max-width: get-media(l)) {
    height: $footer-height-m;
  }

  @media screen and (max-width: get-media(m)) {
    height: $footer-height-s;
  }

  &-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: get-media(l)) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &-logos {
    align-items: center;
    display: flex;

    @media screen and (max-width: get-media(m)) {
      align-items: flex-start;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }
  }

  &-logo {
    $size: 1.75rem;

    height: $size;
    object-fit: contain;

    @media screen and (max-width: get-media(m)) {
      $size: 1.5rem;

      height: $size;
    }

    &--small {
      $size: 1.25rem;

      height: $size;
    }
  }

  &-dot {
    $dot-size: 0.375rem;

    background-color: get-color(basic, dark);
    border: 1px solid get-color(basic, dark);
    border-radius: 100%;
    height: $dot-size;
    margin: 0 2.5rem;
    width: $dot-size;

    @media screen and (max-width: get-media(s)) {
      margin: 0 1.5rem;
    }

    &.is-hidden {
      @media screen and (max-width: get-media(m)) {
        display: none;
      }
    }
  }

  &-left {
    display: flex;
    line-height: 1.5rem;
    align-items: center;
  }

  &-about {
    margin-right: 1rem;
    color: #131313;
    font-weight: 500;

    @media screen and (max-width: get-media(m)) {
      padding-top: 1.5rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
