//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

$button-color: #f2f2f2;

.video_player {
  position: relative;

  &-video {
    height: 100%;
    width: 100%;
  }

  &-auto_height {
    height: auto;
  }

  &-static_width {
    width: 100%;

    @media screen and (min-width: get-media(s)) {
      width: 35.6875rem;
    }
  }

  &-overlay {
    height: 99%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &--content {
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &.hidden {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &-play_button {
    cursor: pointer;
    appearance: none;
    border: none;
    color: get-color(basic, brightest);
    font-size: get-font-size(l);
    padding: 0;
    transition: color 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    height: 3rem;
    width: 3rem;
    background-color: get-color(basic, darker);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: get-media(s)) {
      font-size: get-font-size(xl);
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: get-color(basic, brightest);
      transform: rotate(90deg);
    }
  }
}
