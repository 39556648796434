//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import 'base/variables-definitions';
@import 'base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

// Base Helpers
@import 'base/fonts', 'base/states', 'base/utilities';

// Vendor
@import 'vendor/normalize', 'vendor/bootstrap', 'vendor/react-select';

// Base Files
@import 'base/globals';

.app {
  min-height: $main-height-s;
  position: relative;
  outline: none;

  @media screen and (min-width: get-media(xxs)) {
    display: flex;
  }

  @media screen and (min-width: get-media(m)) {
    min-height: $main-height-m;
  }

  @media screen and (min-width: get-media(l)) {
    min-height: $main-height;
  }

  &-bg_static,
  &-bg_static--revert:before {
    $size: 125rem;

    background-image: url('~assets/images/bg-pattern1920.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;

    @media screen and (max-width: get-media(xxl)) {
      background-size: $size;
    }

    @media screen and (max-width: get-media(m)) {
      background-size: $size / 1.35;
    }

    @media screen and (max-width: get-media(xs)) {
      background-position-x: 100%;
    }
  }

  &-bg_static--complete {
    background-image: url('~assets/images/Pattern-1920-doble.svg');
  }

  &-bg_static--revert {
    background-position-y: 50vh;

    &:before {
      content: '';
      transform: scaleX(-1);
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: block;
      position: absolute;
    }
  }
}
