$grid-breakpoints: (
  xs: 0,
  sm: get-media(xs) + 1,
  md: get-media(s) + 1,
  lg: get-media(m) + 1,
  xl: get-media(l) + 1
);

$container-max-widths: (
  sm: get-media(xs),
  md: get-media(s),
  lg: get-media(m),
  xl: get-media(l)
);

$container-max-widths: (
  l: 1170px,
  xl: 1270px
);

$primary: get-color(primary);
$secondary: get-color(secondary);

$link-color: auto;
$link-hover-color: auto;
$link-hover-decoration: none;

$navbar-light-color: get-color(basic, semidarkest);
$navbar-light-active-color: get-color(primary);
$navbar-light-hover-color: get-color(primary);

$btn-border-radius: 0;
$btn-font-size: get-font-size(s);
$btn-font-family: $font-family-montserrat;
$btn-border-width: 2px;

$modal-content-border-width: 0;
$modal-content-border-radius: 2px;
$modal-header-padding: 0;

$input-padding-y: 0.7rem;
$input-padding-x: 1rem;
$input-border-width: 2px;
$input-border-color: get-color(basic, normal);
$input-placeholder-color: rgba($input-border-color, 0.7);
$input-focus-border-color: get-color(primary, normal);
$input-focus-box-shadow: none;
$input-font-family: $btn-font-family;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/root';
// @import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/images';
// @import '../../../node_modules/bootstrap/scss/type';
// @import '../../../node_modules/bootstrap/scss/code';
@import '../../../node_modules/bootstrap/scss/grid';
// @import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
// @import '../../../node_modules/bootstrap/scss/button-group';
// @import '../../../node_modules/bootstrap/scss/input-group';
// @import '../../../node_modules/bootstrap/scss/custom-forms';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
// @import '../../../node_modules/bootstrap/scss/card';
// @import '../../../node_modules/bootstrap/scss/breadcrumb';
// @import '../../../node_modules/bootstrap/scss/pagination';
// @import '../../../node_modules/bootstrap/scss/badge';
// @import '../../../node_modules/bootstrap/scss/jumbotron';
// @import '../../../node_modules/bootstrap/scss/alert';
// @import '../../../node_modules/bootstrap/scss/progress';
// @import '../../../node_modules/bootstrap/scss/media';
// @import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/modal';
// @import '../../../node_modules/bootstrap/scss/tooltip';
// @import '../../../node_modules/bootstrap/scss/popover';
@import '../../../node_modules/bootstrap/scss/carousel';
@import '../../../node_modules/bootstrap/scss/utilities';
// @import '../../../node_modules/bootstrap/scss/print';

.nav-link.active {
  &:before {
    background-color: get-color(primary);
  }
}

.carousel {
  &-inner,
  &-item {
    height: 100%;
  }
}

.slide {
  padding-bottom: 0.5rem;
}

.container {
  padding: 0 2rem;

  @media screen and (min-width: get-media(s)) {
    max-width: none;
  }

  @media screen and (max-width: get-media(s)) {
    padding: 0 1.5rem;
  }
}

.btn {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(get-color(basic, darkest), 0.26);
  font-size: get-font-size(m) * 0.75;
  font-weight: get-font-weight(bold);
  letter-spacing: 0.86px;
  line-height: 1.75rem;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  white-space: nowrap;

  &.no-shadow {
    box-shadow: none;
  }

  &-dark {
    $dark-color: get-color(basic, darker);

    background-color: $dark-color;
    border-color: $dark-color;
    box-shadow: none;

    &:focus,
    &:hover {
      background-color: get-color(primary);
      border-color: get-color(primary);
    }

    &:active {
      background-color: $dark-color;
      border-color: $dark-color;
      box-shadow: none !important;
    }

    &.is-small {
      padding: 0.25rem 1rem;
      width: 100%;

      @media screen and(min-width: get-media(xxs)) {
        width: auto;
      }
    }

    &.btn-outline {
      background-color: get-color(basic, brightest);
      color: $dark-color;

      &:active {
        background-color: get-color(basic, bright) !important;
        color: $dark-color !important;
      }

      &:focus {
        border-color: get-color(primary) !important;
        background-color: transparent !important;
      }
    }
  }

  &-link {
    box-shadow: none;
    color: get-color(primary, normal);
    cursor: pointer;
    font-size: get-font-size(m);
    font-weight: get-font-weight(normal);
    line-height: get-font-size(xl);
    text-decoration: underline;
    padding: 0;
    text-transform: none;

    &:focus,
    &:active,
    &:hover {
      color: get-color(basic, darkest);
      text-decoration: underline;
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: get-color(basic);
    border-color: get-color(basic);
    color: rgba(get-color(basic, darker), 0.3);
    cursor: not-allowed;
    opacity: 1;
  }
}

.form-check {
  $input-size: 1.5rem;

  align-items: center;
  display: flex;
  padding: 0;

  .form-check-label {
    cursor: pointer;
  }

  .form-check-input {
    position: relative;
    margin: 0;
    margin-right: 0.75rem;
  }

  input {
    appearance: none;
    background-color: get-color(basic, brightest);
    border: 2px solid get-color(basic);
    border-radius: 4px;
    cursor: pointer;
    height: $input-size;
    min-width: $input-size;
    outline: none;
    position: relative;
    transition: get-time(normal);
    width: $input-size;

    &:before {
      transition: get-time(normal);
      position: absolute;
      opacity: 0;
    }

    &:focus,
    &:hover {
      border-color: get-color(basic, semidarker);
    }

    &:checked {
      border-color: get-color(basic, semidarker);

      &:before {
        opacity: 1;
      }
    }

    &:disabled,
    &:disabled:hover {
      color: get-color(basic);
      border-color: get-color(basic);
    }
  }

  /* stylelint-disable */
  &.is-checkbox {
    input {
      &:before {
        background-image: url('~assets/icons/tick.svg');
        background-size: contain;
        background-position: -2px -1px;
        background-repeat: no-repeat;
        content: '';
        height: $input-size;
        width: $input-size;
      }
    }
  }

  &.is-radio {
    input {
      border-radius: 100%;

      &:before {
        background-color: get-color(basic, darker);
        border-radius: 100%;
        content: '';
        height: $input-size / 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $input-size / 2;
      }
    }
  }
  /* stylelint-enable */
}
