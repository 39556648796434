//
// Define global styles for the application,
// usually html and body content.
//

html,
body {
  color: get-color(basic, semidarkest);
  font-family: $font-family-montserrat;
  font-size: 16px;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

p {
  margin: 0;
}
