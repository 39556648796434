//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.skip {
  background: #d6dad9;
  color: #0050ed;
  font-size: 1rem;
  position: fixed;
  padding: 2.4rem 0;
  pointer-events: none;
  text-align: center;
  top: -1000px;
  width: 100%;
  z-index: -1;

  &:focus {
    border: 1px dashed #000;
    outline: none;
    pointer-events: auto;
    text-decoration: underline;
    top: 0;
    z-index: 2500;
  }
}
