//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.share {
  align-items: center;
  display: flex;
  font-size: get-font-size(m);
  font-weight: get-font-weight(normal);
  line-height: 1.5rem;

  @media (max-width: get-media(l)) {
    padding-top: $footer-space;
  }

  &-icon {
    height: $footer-i-size;
    width: $footer-i-size;

    path {
      transition: all get-time(normal);
    }

    &--facebook {
      &:hover path {
        fill: $facebook-color;
      }
    }

    &--twitter {
      &:hover path {
        fill: $twitter-color;
      }
    }

    &--linkedin {
      &:hover path {
        fill: $linkedin-color;
      }
    }
  }
}
