//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.header {
  height: $header-height;
  padding: $header-space 0;
  position: relative;

  @media (max-width: get-media(s)) {
    height: $header-height-s;
  }

  &-container {
    align-items: center;
    display: flex;
    height: $select-height;
    justify-content: space-between;
  }

  &-link {
    color: get-color(primary);
    font-size: get-font-size(l);
    margin: 0;
    padding: 0;
    text-decoration: none;
    transition: get-time(fast);

    &:hover {
      color: get-color(basic, darker);
    }
  }
}
