//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

// Chosen by designer from https://www.davidhu.io/react-spinners/
.spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &-dot1 {
    display: inline-block;
    background-color: get-color(primary, normal);
    width: 1rem;
    height: 1rem;
    margin: 2px;
    border-radius: 100%;
    animation: spinner-animation 0.7s linear 0s infinite normal both running;
  }

  &-dot2 {
    display: inline-block;
    background-color: get-color(primary, normal);
    width: 1rem;
    height: 1rem;
    margin: 2px;
    border-radius: 100%;
    animation: spinner-animation 0.7s linear 0.35s infinite normal both running;
  }

  &-dot3 {
    display: inline-block;
    background-color: get-color(primary, normal);
    width: 1rem;
    height: 1rem;
    margin: 2px;
    border-radius: 100%;
    animation: spinner-animation 0.7s linear 0s infinite normal both running;
  }

  @keyframes spinner-animation {
    50% {
      transform: scale(0.75);
      opacity: 0.2;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
