/* stylelint-disable */
@import '../../node_modules/normalize-scss/sass/_normalize';

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}

h1 {
  font-size: get-font-size(xxl);
}

h2 {
  font-size: get-font-size(xl);
}

h3 {
  font-size: get-font-size(l);
  line-height: 1.75rem;
}

h4 {
  font-size: get-font-size(ml);
}
pre {
  white-space: pre-wrap;
}

pre,
h1,
h2,
h3,
h4,
html,
body {
  margin: 0;
  font-family: $font-family-montserrat;
}

body {
  min-width: get-media(xxs);
}
