//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

.source-link {
  color: get-color(basic, semidarkest);
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}