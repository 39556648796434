//
// Wrappers for project variables, to create a new variable
// please use `variables-definitions.scss` file.
//
// If you want to use a variable, import `variables.scss` file,
// and add the corresponding map-get wrapper here.
//

/* stylelint-disable */
@import '../../../styles/base/variables-definitions';
@import '../../../styles/base/mixins/generic-get';

// Special map for colors
@function get-color($namespace, $variance: normal) {
  $color-map: get($color, $namespace);

  @return get($color-map, $variance);
}

// Generic maps
@function get-time($key: normal) {
  @return get($time, $key);
}
@function get-font-weight($key: m) {
  @return get($font-weight, $key);
}
@function get-font-size($key: m) {
  @return get($font-size, $key);
}
@function get-media($key: m) {
  @return get($media, $key);
}
@function get-z($key: m) {
  @return get($z, $key);
}

/* Some of these styles comes from the banner in www.microsoft.com */
.cc-banner {
  background-color: #f2f2f2;
  box-shadow: 1px 1px 5px 3px rgba(66, 66, 66, 0.5);
  color: #231f20;
  display: block;
  font-size: 0.75rem;
  left: 0;
  padding: 0.1rem;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;

  &.active {
    display: block;
  }

  @media (min-width: 768px) {
    font-size: 0.8125rem;
    padding: 0 1.65rem;
  }

  @media (min-width: 1084px) {
    padding: 0;
  }

  .cc-container {
    align-items: center;
    display: inline-flex;
    margin: 0;
    padding: 0.75em 0;
    text-align: left;
    text-decoration: none;
    width: 90%;

    .cc-icon {
      height: 1.846em;
      margin: 0;
      padding: 0;
      text-decoration: none;
      width: 1.846em;

      &-close {
        height: 1rem;
        width: 1rem;
      }
    }

    .cc-v-center {
      display: inline;
      vertical-align: middle;
    }

    .cc-text {
      margin-left: 0.5rem;
      margin-right: 1.5rem;
    }

    .cc-link {
      text-decoration: none;
      color: #0067b8;
    }

    .cc-float-right {
      float: right;
    }

    .cc-description_wrapper {
      flex-grow: 1;
    }

    .cc-close_wrapper {
      width: 2rem;
    }
  }
}
